<template>
  <div class="zoom-button-container">
    <button @click="decreaseZoom" class="round-button">
      <v-icon>mdi-minus</v-icon>
    </button>
    <span>{{ formattedScale }}</span>
    <button @click="increaseZoom" class="round-button">
      <v-icon>mdi-plus</v-icon>
    </button>
  </div>
</template>

<script>
export default {
  name: 'PDFZoomControls',
  props: {
    modelValue: {
      type: Number,
      required: true
    },
    minScale: {
      type: Number,
      default: 0.25
    },
    maxScale: {
      type: Number,
      default: 2
    },
    step: {
      type: Number,
      default: 0.25
    }
  },
  computed: {
    formattedScale() {
      return `${(this.modelValue * 100).toFixed(0)}%`;
    }
  },
  methods: {
    increaseZoom() {
      if (this.modelValue < this.maxScale) {
        this.$emit('update:modelValue', this.modelValue + this.step);
      }
    },
    decreaseZoom() {
      if (this.modelValue > this.minScale) {
        this.$emit('update:modelValue', this.modelValue - this.step);
      }
    }
  }
};
</script>

<style scoped>
  .zoom-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 15px;
  }

  .round-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }

  .round-button:hover {
    background-color: #e0e0e0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .round-button:active {
    background-color: #d0d0d0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  .round-button v-icon {
    font-size: 20px;
    color: #555;
  }
</style>
