<template>
  <v-app>
    <v-main>
      <cookies />
      <div class="split left">
        <div class="centered">
          <img
            alt="ClinfAI logo"
            :src="require('@/assets/img/clinfai.png')"
            class="responsive-img"
          />
        </div>
      </div>
      <div class="split right">
        <AlertNotification v-if="alert" :text="loginError[0]" :type="alertType" />
        <v-container fluid fill-height>
          <v-row justify="center">
            <v-col cols="12" sm="8" md="8">
              <v-form>
                <v-card
                  v-if="errors.length"
                  class="red--text"
                  font-family="Poppins"
                >
                  Please enter the correct username and password. <br />
                  Note that both fields may be case-sensitive.
                </v-card>
                <v-text-field
                  v-model="study"
                  label="Study Group"
                  solo
                  class="rounded-pill"
                  placeholder="Study"
                  prepend-icon="mdi-folder-key"
                  required
                  color="#6E57F8"
                ></v-text-field>
                <v-text-field
                  v-model="email"
                  label="E-mail"
                  solo
                  :rules=emailRules
                  class="rounded-pill"
                  id="email"
                  placeholder="Email"
                  required
                  prepend-icon="mdi-account"
                  color="#6E57F8"
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  label="Password"
                  type="password"
                  solo
                  class="rounded-pill"
                  id="password"
                  placeholder="Password"
                  prepend-icon="mdi-lock"
                  required
                  color="#6E57F8"
                  @keyup.enter="login"
                ></v-text-field>
              </v-form>
              <v-spacer></v-spacer>
              <v-btn
                block
                color="#6E57F8"
                class="white--text "
                font-family="Poppins"
                @click="login"
                :disabled="formValid"
              >
                Sign In
              </v-btn>
              <br/>
              <div class="text-forgot-pass">
                <router-link to="/forgotPassword">
                  Forgot your password?
                </router-link>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-main>
  </v-app>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { userServices } from '../services/userService';
import { cookieExpiration } from '../utils/cookieExpiration';
import Cookies from '../components/CookiesView.vue';
import AlertNotification from '../components/AlertNotification.vue';

const email = ref('');
const password = ref('');
const study = ref('');
const role = ref('');
const loginError = ref([])
const errors = ref([]);
const alert = ref(false);
const alertType = ref('error');
const router = useRouter();
const emailRules = [(v) => !!v || 'E-mail is required', (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'];
const formValid = computed(() => !(email.value && password.value && study.value));

const login = async () => {
  const data = {
    email: email.value,
    password: password.value,
    study_name: study.value,
  };
  try {
    const response = await userServices.Login(data);
    auxLogin(response);
  } catch (error) {

    if (error.response && error.response.data) {
      loginError.value = error.response.data.email || error.response.data.non_field_errors ;
    } else {
      loginError.value = ['Error login'];
    }
    alert.value = true;
    setTimeout(() => {
      alert.value = false;
      alertType.value = "error";
      loginError.value = [];
    }, 3000);
  }
};

const checkActiveUser = async (authorization) => {
  try {
    const response = await userServices.checkUserData(authorization);
    role.value = response.data.role;
    const register_name = response.data.first_name;
    const register_last_name = response.data.last_name;
    localStorage.clear();
    localStorage.setItem('role', role.value);
    localStorage.setItem('userRegisterName', register_name);
    localStorage.setItem('UserRegisterLastName', register_last_name);
    const expiration = cookieExpiration();
    document.cookie = `role=${role.value}; expires=${expiration.toUTCString()}`;
    if (response.data.is_active) {
      router.push('/home');
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

const auxLogin = async (resp) => {
  if (resp.status === 200) {
    localStorage.setItem('username', email.value);
    const expiration = cookieExpiration();
    document.cookie = `Authorization=${resp.data.token}; expires=${expiration.toUTCString()}`;
    document.cookie = `user=${email.value}; expires=${expiration.toUTCString()}`;
    document.cookie = `study=${study.value}; expires=${expiration.toUTCString()}`;
    checkActiveUser(resp.data.token);
  }
};
</script>

<style scoped lang="scss">
  body {
    font-family: 'Poppins', sans-serif;
  }

  .alertbox {
    position: fixed;
    left: 80%;
    margin: 0 auto;
    z-index: 1;
    margin-bottom: 500px;
  }

  .v-card__actions {
    display: grid !important;
  }

  #app,
  .v-application--wrap {
    background-color: #2c529b;
  }

  .v-application--wrap {
    color: lightgray;
  }

  .split {
    height: 100%;
    width: 50%;
    position: fixed;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    &.left {
      left: 0;
      background: linear-gradient(90deg, #6e57f8 0%, #14c5e0 100%);
    }

    &.right {
      right: 0;
      background-color: white;
    }
  }

  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .responsive-img {
    max-width: 100%;
    width: auto;
    height: auto;
  }

  .text-forgot-pass {
    text-align: center !important;
  }

  @media (max-width: 768px) {
    .responsive-img {
      min-width: 100%;
    }
  }

  @media (max-width: 480px) {
    .responsive-img {
      min-width: 100%;
    }
  }
</style>
