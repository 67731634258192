<template lang="">
  <div>
    <v-alert class="alertbox" v-if="alert"
      :value="alert"
      type="error"
      transition="scale-transition"
    >{{error}}
    </v-alert>
    <div class="expansion-container">
      <v-expansion-panels class="expansion-card-password">
        <v-expansion-panel>
          <v-expansion-panel-title
              class="title-password">
              Change your password
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="oldPassword"
                    label="Old password"
                    type="password"
                    class="form-input"
                    placeholder="Password"
                    color="#6E57F8"
                    required
                  ></v-text-field>
                  <v-text-field
                      v-model="newPassword"
                      label="New password"
                      type="password"
                      class="form-input"
                      placeholder="Password"
                      color="#6E57F8"
                      required
                  ></v-text-field>
                  <v-text-field
                      v-model="repeatedPassword"
                      label="Repeat new password"
                      type="password"
                      class="form-input"
                      placeholder="Password"
                      color="#6E57F8"
                      required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <div class="change-font" >
                      <v-card-text class="password-text">
                        Password must contain:
                      </v-card-text>
                      <v-list-item class="password-rules">
                        <v-icon
                          @change="colorLenRules"
                          :color="colorLength">mdi-check-bold
                        </v-icon>{{ lengthRule }}
                      </v-list-item><br>
                      <v-list-item class="password-rules">
                        <v-icon
                          @change="colorUpperRules"
                          :color="colorUpper">mdi-check-bold
                        </v-icon>{{ upperRule }}
                        </v-list-item><br>
                      <v-list-item class="password-rules">
                        <v-icon
                          @change="colorLowerRules"
                          :color="colorLower">mdi-check-bold
                        </v-icon>{{ lowerRule }}
                        </v-list-item><br>
                      <v-list-item class="password-rules">
                        <v-icon
                          @change="colorNumRules"
                          :color="colorNumber">mdi-check-bold
                        </v-icon>{{ numberRule }}
                        </v-list-item><br>
                      <v-list-item class="password-rules">
                        <v-icon
                          @change="colorCharRules"
                          :color="colorCharacter">mdi-check-bold
                        </v-icon>{{ characterRule }}
                      </v-list-item><br>
                  </div>
                  <v-btn
                      :style="{left: '50%', transform:'translateX(-50%)',color: 'white'}"
                      color="#6E57F8"
                      class="white--text"
                      @click="savePassword"
                      :disabled="formValid"
                  >
                      Change my password
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>
<script>
import Cookies from 'js-cookie';
import { userServices } from '../services/userService';

export default {
  name: 'ChangePassword',
  data() {
    return {
      items: [
        { title: 'Home', icon: 'mdi-home-city' },
        { title: 'My Account', icon: 'mdi-account' },
        { title: 'Users', icon: 'mdi-account-group-outline' },
      ],
      newPassword: '',
      repeatedPassword: '',
      oldPassword: '',
      userId: '',
      authorization: Cookies.get('Authorization'),
      error: '',
      alert: false,
      lengthRule: 'At least 8 charactes.',
      upperRule: 'At least 1 upper case letter (A..Z)',
      lowerRule: 'At least 1 lower case letter (a..z)',
      numberRule: 'At least 1 number (0...9)',
      characterRule: 'At least 1 special character (/..-)',
      colorLength: 'grey',
      colorUpper: 'grey',
      colorLower: 'grey',
      colorNumber: 'grey',
      colorCharacter: 'grey',
    };
  },
  computed: {
    passwordRules() {
      return this.passwordStrong();
    },
    colorLenRules() {
      return this.passwordLen();
    },
    colorUpperRules() {
      return this.passwordUpper();
    },
    colorLowerRules() {
      return this.passwordLower();
    },
    colorNumRules() {
      return this.passwordNum();
    },
    colorCharRules() {
      return this.passwordChar();
    },
    formValid() {
      const validPassword = this.passwordStrong(this.newPassword);
      return !((validPassword)
      && (this.oldPassword !== this.newPassword)
      && (this.newPassword === this.repeatedPassword)
      && (this.oldPassword !== ''
      && this.newPassword !== ''
      && this.repeatedPassword !== ''));
    },
  },
  methods: {
    passwordStrong() {
      const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@_!%&*?])[A-Za-z\d#$_@!%&*?]{8,30}/;
      return () => pattern.test(this.newPassword);
    },
    passwordLen() {
      const checkLength = /^[A-Za-z\d#$_@!%&*?]{8,30}/;
      if (checkLength.test(this.newPassword) === true) {
        this.colorLength = 'green';
        return checkLength.test(this.newPassword);
      }
      this.colorLength = '';
      return false;
    },
    passwordUpper() {
      const checkUpper = /^(?=.*[A-Z])/;
      if (checkUpper.test(this.newPassword) === true) {
        this.colorUpper = 'green';
        return checkUpper.test(this.newPassword);
      }
      this.colorUpper = '';
      return false;
    },
    passwordLower() {
      const checkLower = /^(?=.*[a-z])/;
      if (checkLower.test(this.newPassword) === true) {
        this.colorLower = 'green';
        return checkLower.test(this.newPassword);
      }
      this.colorLower = '';
      return false;
    },
    passwordNum() {
      const checkNumber = /^(?=.*\d)/;
      if (checkNumber.test(this.newPassword) === true) {
        this.colorNumber = 'green';
        return checkNumber.test(this.newPassword);
      }
      this.colorNumber = '';
      return false;
    },
    passwordChar() {
      const checkCharacter = /^(?=.*[#$@_!%&*?])/;
      if (checkCharacter.test(this.newPassword) === true) {
        this.colorCharacter = 'green';
        return checkCharacter.test(this.newPassword);
      }
      this.colorCharacter = '';
      return false;
    },
    savePassword() {
      const formData = new FormData();
      formData.append('old_password', this.oldPassword);
      formData.append('new_password1', this.newPassword);
      formData.append('new_password2', this.repeatedPassword);
      userServices.changePassword(this.authorization, formData)
        .then((response) => {
          this.auxSavePassword(response);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('error folder:', error);
        });
    },
    auxSavePassword(response) {
      if (response.status === 200) {
        this.$router.push('/home');
      }
    },
  },
};
</script>
<style>
  .save-style{
  font-family: 'Poppins', sans-serif;
  width: auto;
  margin:  auto;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  }
  .change-font {
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    margin-bottom: 20px;
  }
  .form-input{
    margin: 24px;
    font-family: 'Poppins', sans-serif;
  }
  .password-rules{
    min-height: 0;
  }
  .title-password{
    font-size: 18px !important;
    font-family: 'Poppins', sans-serif;
  }
  .expansion-card-password{
    border-radius: 16px !important;
    width: 100%;
    margin-top: 32px;
  }
  .expansion-container {
    display: flex;
    justify-content: center !important;
    font-family: 'Poppins', sans-serif;
  }
</style>
