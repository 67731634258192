<template>
  <v-dialog v-model="localShowModal" max-width="30%" persistent>
    <v-card id="delete-dialog">
    <v-card-title class="dialog-title">
      Confirm Action
    </v-card-title>
    <v-card-text>
      <span id="text-delete-dialog" class="custom-text">
        {{ contentText }}
      </span>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <!-- Botón de cancelar -->
      <v-btn
        id="cancel-delete-btn"
        color="rgb(101,123,219)"
        text
        @click="closeModal"
      >
        No
      </v-btn>
      <!-- Botón de aceptar -->
      <v-btn
        elevated
        class="btn-accept"
        id="confirm-delete-btn"
        dark
        @click="confirmAction"
      >
        Yes
      </v-btn>
    </v-card-actions>
  </v-card>
  </v-dialog>
</template>
  
<script>
  import { ref, watch } from 'vue';
  export default {
    name: 'GenericDeleteModal',
    props: {
      showModal: {
        type: Boolean,
        required: true
      },
      title: {
        type: String,
        default: null
      },
      buttonText: {
        type: String,
        default: 'Accept'
      },
      contentText: {
        type: String,
        default: 'Are you sure?'
      },
      onSuccess: {
        type: Function,
        default: null
      }
    },
    setup(props, { emit }) {
      const localShowModal = ref(props.showModal);
  
      async function confirmAction() {
        try {
          if (props.onSuccess) {
            props.onSuccess();
          }
        } catch (err) {
          console.error("error confirm action: " + err);
        }
      }
  
      function closeModal() {
        emit('close');
      }
  
      watch(() => props.showModal, (newVal) => {
        localShowModal.value = newVal;
      });
  
      return {
        localShowModal,
        confirmAction,
        closeModal
      };
    }
  };
</script>
  
<style scoped>
  .dialog-title {
    font-weight: bold;
    color: white;
    text-align: center;
    background: linear-gradient(
      90deg,
      #6E57F8 0%,
      #14C5E0 100%
    ) !important;
  }
  .btn-accept {
    background-color: #6E57F8 !important;
    color: white !important;
  }
  .btn-cancel {
    color: #6E57F8 !important;
  }
  .custom-text {
    font-family: 'Poppins', sans-serif;
  }

</style>
  