<template>
  <div class="text-center">
    <v-dialog
      id="cookie-modal-dialog"
      v-model="dialog"
      width="500"
      persistent
    >
      <v-card id="cookie-modal-card">
        <v-card-title id="text-cookie-card-title" class="text-h5 cookies-title">
          Use of cookies
        </v-card-title>
        <v-card-text id="text-cookie-card-text" class="pt-4 cookies-text">
          This website uses cookies. You accept the treatment of cookies for the purpose of improving the application
          and the restriction of use of the application of thirty minutes.
          If you agree to the processing of your data, click accept.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-switch
            v-model="switch1"
            readonly
            color="#657bdb"
            inset
            label="Mandatory cookies"
          ></v-switch>
          <v-spacer></v-spacer>
          <v-btn
            id="accept-cookie-btn"
            class="accept-btn"
            color="#6E57F8"
            variant="text"
            @click="dialog = false"
          >
            I accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script setup>
import { ref } from 'vue';

const dialog = ref(true);
const switch1 = ref(true);
</script>

<style scoped lang="scss">
  body {
    font-family: 'Poppins', sans-serif;
  }

  .cookies-title {
    color: #ffffff !important;
    background: linear-gradient(
      90deg,
      #6E57F8 0%,
      #14C5E0 100%
    );
    border-top-left-radius: 8px;  // Borde superior izquierdo redondeado
    border-top-right-radius: 8px; // Borde superior derecho redondeado
  }

  .cookies-text {
    font-family: 'Poppins', sans-serif !important;
  }

  #cookie-modal-card {
    border-radius: 12px;  // Bordes redondeados para todo el card del diálogo
    overflow: hidden;     // Evita que el contenido se salga del borde redondeado
  }

  .v-dialog {
    border-radius: 12px; // Bordes redondeados para el diálogo
  }
</style>
