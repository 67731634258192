<template>
  <v-app>
    <v-main>
      <v-container fluid class="forgot-password-container">
        <v-row justify="center" align="center" class="full-height">
          <v-col cols="12" md="8" lg="6" class="text-center">
            <!-- Imagen centrada -->
            <v-img
              src="@/assets/img/clinfai-blanco-gradient.png"
              alt="Temfe logo"
              contain
              class="logo-image"
            ></v-img>

            <!-- Avatar e Icono -->
            <v-avatar color="white" size="100" class="mx-auto my-6">
              <v-icon size="50" color="#657bdb">mdi-email</v-icon>
            </v-avatar>

            <!-- Mensajes -->
            <h2 class="forgot-pass-title">Check your email</h2>
            <p class="muted-text">
              We sent you an email with a new password.
            </p>

            <!-- Enlaces -->
            <div class="signin-text mt-8">
              Didn't receive the email?
              <router-link class="signin-hyperlink" to="/forgotPassword">
                Click to resend
              </router-link>
            </div>
            <div class="signin-text mt-4">
              <router-link class="signin-hyperlink" to="/login">
                Sign in
              </router-link>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<style scoped>
.forgot-password-container {
  background-color: white;
  min-height: 100vh;
  padding: 0;
}

.full-height {
  min-height: 100vh;
}

.logo-image {
  max-width: 500px;
  margin: 0 auto;
}

.forgot-pass-title {
  color: #657bdb;
  font-size: 32px;
  margin-bottom: 16px;
  font-family: 'Poppins', sans-serif;
}

.muted-text {
  font-size: 18px;
  color: #000;
  font-family: 'Poppins', sans-serif;
}

.signin-text {
  font-size: 16px;
  color: #000;
  font-family: 'Poppins', sans-serif;
}

.signin-hyperlink {
  color: #657bdb;
  text-decoration: none;
  margin-left: 4px;
}

.signin-hyperlink:hover {
  text-decoration: underline;
}
</style>
