import { coreServices } from './coreService';
import { defaultHeaders } from './apiConfig';

export const logoutServices = {

  logout(authorization) {
    const url = `${process.env.VUE_APP_USER_API_URL}/logout/`;
    return coreServices.post(url, {}, defaultHeaders(authorization));
  },
};
