<template>
  <div>
    <app-header />
    <nav-bar />
    <div>
      <div class="edition"></div>
    </div>
  </div>
</template>
<script>
import AppHeader from '../components/AppHeader.vue';
import NavBar from '../components/NavBar.vue';

export default {
  name: 'HomeView',
  components: {
    AppHeader,
    NavBar,
  },
  data() {
    return {
      items: [
        { title: 'Home', icon: 'mdi-home-city' },
        { title: 'My Account', icon: 'mdi-account' },
        { title: 'Users', icon: 'mdi-account-group-outline' },
      ],
    };
  },
};
</script>
<style>
.v-main__wrap {
  background-color: #F7F7F7 !important;
}
.edition {
  font-family: 'Poppins', sans-serif;
}
</style>
