<template>
  <div v-if="showLoading">
    <v-progress-circular
      :color="color"
      :rotate="360"
      :size="size"
      :width="width"
      indeterminate
    >
    <v-icon
      v-if="showIcon"
      class="custom-icon"
      :color="color"
      size="24"
      >
        {{icon}}
      </v-icon>
    </v-progress-circular>
  </div>
</template>

<script>
export default {
  name: 'CircularLoader',
  props: {
    showLoading: {
      type: Boolean,
      default: false
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: "mdi-upload"
    },
    color: {
      type: String,
      default: '#6E57F8'
    },
    size: {
      type: Number,
      default: 100
    },
    width: {
      type: Number,
      default: 15
    }
  }
}
</script>
<style scoped>

.custom-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>