<template>
  <div class="main-container">
    <app-header />
    <nav-bar />
    <v-container fluid class="container-styles">
      <div class="content">
        <v-card class="profile-style">
          <v-row>
            <v-col
              align-self="start"
              class="ml-8 mt-3 avatar-style"
            >
              <v-avatar
                class="avatar-size"
                size="164"
                color="#6E57F8"
              >
                <span class="white--text text-h2">
                  {{ initials }}
                </span>
              </v-avatar>
            </v-col>
            <v-col class="user-card-style">
              <v-row>
                <v-col>
                  <v-card-title class="fullname-profile">
                    <strong>{{ register_name }} {{ register_last_name }}</strong>
                  </v-card-title>
                  <v-card-subtitle class="role-profile">{{ role }}</v-card-subtitle>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-card-text>
                    <v-icon
                      color="#6E57F8"
                      class="mr-2"
                    >
                      mdi-folder
                    </v-icon>{{ study }}
                  </v-card-text>
                  <v-card-text>
                    <v-icon
                      color="#6E57F8"
                      class="mr-2"
                    >
                      mdi-email
                    </v-icon>{{ email }}
                  </v-card-text>
                  <v-card-text>
                    <v-icon
                      color="#6E57F8"
                      class="mr-2"
                    >
                      mdi-briefcase-account
                    </v-icon>{{ company }}
                  </v-card-text>
                </v-col>
                <v-col>
                  <v-card-text>
                    <v-icon
                      color="#6E57F8"
                      class="mr-2"
                    >
                      mdi-account-tie
                    </v-icon>{{ company_role }}
                  </v-card-text>
                  <v-card-text>
                    <v-icon
                      color="#6E57F8"
                      class="mr-2"
                    >
                      mdi-home-city
                    </v-icon>{{ region }}
                  </v-card-text>
                  <v-card-text>
                    <v-icon
                      color="#6E57F8"
                      class="mr-2"
                    >
                      mdi-map-marker
                    </v-icon>{{ zip }}
                  </v-card-text>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
        <change-password class="mx-auto" />
        <user-documents-reads class="mx-auto" />
      </div>
    </v-container>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import AppHeader from '../components/AppHeader.vue';
import NavBar from '../components/NavBar.vue';
import ChangePassword from '../components/ChangePassword.vue';
import UserDocumentsReads from '../components/UserDocumentsReads.vue';
import { userServices } from '../services/userService';

export default {
  name: 'UserProfile',
  components: {
    AppHeader,
    NavBar,
    ChangePassword,
    UserDocumentsReads,
  },
  data() {
    return {
      register_name: ' ',
      register_last_name: null,
      initials: ' ',
      role: '',
      email: '',
      company: '',
      company_role: '',
      region: '',
      zip: '',
      authorization: Cookies.get('Authorization'),
      study: Cookies.get('study'),
    };
  },
  mounted() {
    this.checkUserData();
  },
  methods: {
    checkUserData() {
      userServices
        .checkUserData(this.authorization)
        .then((response) => {
          this.initials = response.data.first_name.charAt(0) + response.data.last_name.charAt(0);
          this.register_name = response.data.first_name;
          this.register_last_name = response.data.last_name;
          this.role = response.data.role;
          this.email = response.data.email;
          this.company_role = response.data.company_role;
          this.company = response.data.company;
          this.region = response.data.region;
          this.zip = response.data.zip;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-style {
  display: flex;
  padding: 24px;
  justify-content: center !important;
  font-family: 'Poppins', sans-serif;

  .role-profile {
    margin: auto !important;
  }

  .avatar-style {
    flex: 1;
  }

  .user-card-style {
    flex: 2;
  }
}

.v-expansion-panel--active {
  border-radius: 16px !important;
}

.content {
  margin-top: 30px;
  margin-bottom: 40px;
  @media screen and (max-width: 770px) {
    margin-left: auto;
    width: 100%;
  }

  @media screen and (max-width: 470px) {
    margin-left: auto;
  }
}

@media screen and (max-width: 470px) {
  .avatar-size {
    height: 90px !important;
    width: 90px !important;
    min-width: 90px !important;
    margin-top: 50px;
  }

  .user-card-style {
    margin-top: 20px;
    margin-left: 20px !important;
  }

  .fullname-profile {
    font-size: 20px;
  }
}
</style>