<template>
  <app-header />
  <nav-bar />
  <v-container fluid class="container-styles">
    <div class="content">
      <!-- <v-alert v-if="alert" type="error" transition="scale-transition">{{ error }}</v-alert> -->
      <AlertNotification v-if="alert" :text="error" :type="alertType" />
      <v-card class="upload-style">
        <v-row>
          <v-col cols="4">
            <v-alert dense icon="$info" outlined prominent class="custom-alert" border>
              Set Document <strong>Folder</strong> Location
            </v-alert>
            <v-treeview :items="items" item-value="name" class="ml-8" color="#6E57F8" activatable> 
              <template v-slot:prepend="{ item, isOpen }">
                <v-icon v-if="!item.file" @click="getFolderId(item)">
                  {{ isOpen ? 'mdi-folder-open' : 'mdi-folder' }}
                </v-icon>
                <v-icon v-else>
                  {{ files[item.file] }}
                </v-icon>
                {{ item.name }}
              </template>
            </v-treeview>
          </v-col>
          <v-col cols="8" class="selectfile-style">
            <v-alert dense icon="$info" outlined prominent class="custom-alert">
              <div v-html="alertContent"></div>
            </v-alert>
            <div class="rounded-border">
              <h2 class="upload-title">File Upload Information</h2>
              <v-form ref="form" v-model="valid" class="upload-title">
                <v-row>
                  <v-col cols="12" md="10">
                    <v-text-field
                      v-model="patientTrialCode"
                      label="Patient Trial Code"
                      required
                      color="#6E57F8"
                      placeholder="Enter Patient Trial Code"
                      outlined
                      prepend-icon="mdi-account"
                      @input="validatePatientTrialCode"
                      :error-messages="patientTrialCodeError"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="10">
                    <v-file-input
                      v-model="files_model"
                      id="file"
                      label="File input"
                      color="#6E57F8"
                      placeholder="Select your file"
                      prepend-icon="mdi-paperclip"
                      :show-size="1000"
                      @change="addFiles"
                    >
                      <template v-slot:selection="{ fileNames }">
                        <div>
                          <v-chip v-for="(fileName, index) in fileNames" :key="index" color="#6E57F8" dark label small>
                            {{ fileName }}
                          </v-chip>
                          <span v-if="fileNames.length > 2" class="overline grey--text text--darken-3 mx-2">
                            +{{ fileNames.length - 2 }} File(s)
                          </span>
                        </div>
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="5">
                    <v-menu v-model="menuStartDate" :close-on-content-click="false" transition="scale-transition">
                      <template #activator="{ props }">
                        <v-text-field
                          class="p-calendar"
                          v-model="formattedStartDateTime"
                          label="Start Date"
                          prepend-icon="mdi-calendar"
                          v-bind="props"
                          color="#6E57F8"
                          outlined
                          required
                        ></v-text-field>
                      </template>
                      <v-card class="custom-calendar-card">
                        <p-calendar v-model="startDateTime" showTime inline hourFormat="24" class="calendar-custom"></p-calendar>
                        <v-card-actions class="custom-card-action">
                          <v-btn text color="primary" @click="menuStartDate = false">OK</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-menu v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition">
                      <template #activator="{ props }">
                        <v-text-field
                          v-model="formattedEndDateTime"
                          label="End Date"
                          prepend-icon="mdi-calendar"
                          v-bind="props"
                          color="#6E57F8"
                          outlined
                          required
                        ></v-text-field>
                      </template>
                      <v-card class="custom-calendar-card">
                        <p-calendar v-model="endDateTime" showTime inline hourFormat="24" class="calendar-custom"></p-calendar>
                        <v-card-actions  class="custom-card-action">
                          <v-btn text color="primary" @click="menuEndDate = false">OK</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="2" class="d-flex clear-dates-styles">
                    <v-btn
                      icon
                      @click="clearDates"
                      :disabled="removeButtonDisabled"
                      title="Remove dates" class="icon-only-btn">
                      <v-icon color="red">mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-btn class="white--text" color="#6E57F8" @click="submitUpload" :disabled="uploadDisabled">
                      Upload
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </div>
          </v-col>
        </v-row>
      </v-card>

      <!-- Overlay for the loader -->
      <div v-if="loading" class="overlay">
        <div class="loader-container">
          <CircularLoader :showLoading="loading" showIcon="true"/>
        </div>
      </div>
    </div>
  </v-container>
</template>


<script>
import Cookies from 'js-cookie';
import AppHeader from '../components/AppHeader.vue';
import NavBar from '../components/NavBar.vue';
import { fileServices } from '../services/fileServices';
import { VTreeview } from 'vuetify/labs/VTreeview';
import DatePicker from 'primevue/datepicker';
import CircularLoader from "../components/CircularLoader.vue";
import AlertNotification from '../components/AlertNotification.vue';

export default {
  name: 'UploadFile',
  components: {
    AppHeader,
    NavBar,
    VTreeview,
    CircularLoader,
    AlertNotification,
    'p-calendar': DatePicker
  },
  data() {
    return {
      files_model: null,
      patientTrialCode: '',
      patientTrialCodeError: '',
      startDateTime: null,
      endDateTime: null,
      menuStartDate: false,
      menuEndDate: false,
      authorization: Cookies.get('Authorization'),
      study: Cookies.get('study'),
      folderId: 0,
      items: [],
      files: {
        pdf: 'mdi-file-pdf',
        png: 'mdi-file-image',
        txt: 'mdi-file-document-outline',
      },
      loading: false,
      size: '45px',
      color: 'rgb(101,123,219)',
      selectedFolder: false,
      fileName: [],
      fileAttachments: [],
      fileExists: false,
      alert: false,
      error: '',
      valid: false,
      removeButtonDisabled: true,
      alertContent: `
        Please follow these steps to upload your document:<br>
        1. <strong>Select the folder</strong> where you want to save the document.<br>
        2. Assign the <strong>patient trial code</strong>.<br>
        3. Select the file by clicking on the upload button.<br>
        4. Click the <strong>Upload</strong> button to complete the process.
      `
    };
  },
  computed: {
    uploadDisabled() {
      let disabledBtn = !(this.folderId && this.patientTrialCode && this.files_model)
      return disabledBtn
    },
    formattedStartDateTime() {
      return this.startDateTime ? this.startDateTime.toLocaleString() : '';
    },
    formattedEndDateTime() {
      return this.endDateTime ? this.endDateTime.toLocaleString() : '';
    }
  },
  watch: {
    files_model() {
      this.fileExists = this.checkIfFileExists();
    },
    startDateTime(val) {
      this.formattedStartDateTime = this.formatDate(val);
    },
    endDateTime(val) {
      this.formattedEndDateTime = this.formatDate(val);
      this.removeButtonDisabled = !this.endDateTime && !this.startDateTime;
    }
  },
  methods: {
    checkIfFileExists() {
      fileServices.getMetadataFiles(this.authorization, this.folderId)
        .then(() => {
          if (this.fileAttachments.length >= 1) {
            this.fileExists = Array.from(this.fileAttachments).every((file) => {
              if (this.fileName.includes(file.name)) {
                this.alert = true;
                this.error = 'File already exists in the folder';
                setTimeout(() => {
                  this.alert = false;
                  this.error = '';
                }, 3000);
                return true;
              }
              return false;
            });
          }
        });
    },
    getItems() {
      if (this.items.length > 0) {
        this.items = [];
      }
      fileServices
        .getItems(this.authorization, this.study)
        .then((response) => {
          const { data } = response;
          this.items.push(data);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log('error folder:', error);
        });
    },
    getFolderId(item) {
      this.folderId = item.folder_id;
      console.log("get folder: " + this.folderId)
      this.parentId = item.parent_id;
      this.selectedFolder = true;
      fileServices
        .getMetadataFiles(this.authorization, this.parentId)
        .then((response) => {
          const { data } = response;
          this.fileName = data;
        });
    },
    addFiles(e) {
      this.fileAttachments = e;
    },
    submitUpload() {
      const archive = document.querySelector('#file');
      const formData = new FormData();
      formData.append('patient_trial_code', this.patientTrialCode);
      if (this.startDateTime) {
        this.startVisualizationDate = new Date(this.startDateTime).toISOString();
        formData.append('start_visualization_date', this.startVisualizationDate);
      }
      if (this.endDateTime) {
        this.endVisualizationDate = new Date(this.endDateTime).toISOString();
        formData.append('end_visualization_date', this.endVisualizationDate);
      }

      formData.append('study_name', this.study);
      formData.append('parent_id', this.parentId);
      formData.append('folder_id', this.folderId);
      for (let i = 0; i < archive.files.length; i += 1) {
        formData.append('file_location', archive.files[i]);
        formData.append('title', archive.files[i].name);
      }
      fileServices.uploadFile(
        this.authorization,
        formData,
      )
        .then(() => {
          this.$router.push('/library');
        })
        .catch((error) => {
          this.loading = false;
          if (error.data.non_field_errors) {
            this.showErrorAlert(error.data.non_field_errors[0]);
          } else {
            this.showErrorAlert('Error trying to modify dates');
          }
        });

      this.loading = true;
    },
    validatePatientTrialCode() {
      const invalidCharacters = /[^a-zA-Z0-9]/;
      if (invalidCharacters.test(this.patientTrialCode)) {
        this.patientTrialCodeError = 'No spaces or special characters allowed';
      } else {
        this.patientTrialCodeError = '';
      }
    },
    showAlert(message, type) {
      this.alertType = type;
      this.error = message;
      this.alert = true;
      setTimeout(() => {
        this.alertType = '';
        this.alert = false;
      }, 4000);
    },
    showErrorAlert(message) {
      this.showAlert(message, 'error');
    },
    showConfirmButton() {
      this.isEditing = true;
    },
    formatDate(date) {
      return date ? date.toLocaleString() : '';
    },
    clearDates() {
      this.startDateTime = null;
      this.endDateTime = null;
      this.removeButtonDisabled = true;
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>
<style lang="scss" scoped>
.content {
  margin-top: 30px;
  margin-bottom: 20px;
  position: relative;
}

body {
  background: linear-gradient(90deg, #6E57F8 0%, #14C5E0 100%) !important;
  font-family: 'Poppins', 'Futura', sans-serif;
}

.p-datepicker {
  max-width: 100% !important;
  margin-left: 15px !important;
  margin-bottom: 2px !important;
}

.custom-calendar-card {
  max-width: 380px;
  padding: 10px;
}

.custom-card-action {
  display: flex;
  justify-content: flex-end; 
}

.calendar-custom {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.p-component {
  margin: 10px;
}
.p-datepicker {
  width: 100%; /* Ajusta el ancho del calendario */
}

.p-datepicker-inline {
  display: block; /* Forza el modo inline a ser más legible */
}

.p-datepicker .p-datepicker-header {
  padding: 10px 16px; /* Ajusta el padding en el encabezado */
}

.p-datepicker .p-datepicker-time-panel {
  padding: 8px 16px; /* Ajusta el padding para la sección de tiempo */
}

.custom-alert {
  color: #6E57F8 !important; /* Color del texto */
  background-color: white !important; /* Fondo blanco */
  border-color: #6E57F8 !important; /* Color del borde */
  border-width: 1px !important; /* Ancho del borde */
  border-style: solid !important; /* Estilo del borde */
  border-radius: 8px !important; /* Bordes redondeados */
  box-shadow: none !important; /* Sin sombra */
}

.custom-alert .v-alert__icon {
  color: #6E57F8 !important; /* Color del icono */
}

.v-container {
  padding: 16px;
}

.selectfile-style {
  font-family: "Poppins", sans-serif;
  width: 80%;
  height: 840px;
  padding-top: 10px;
  padding-bottom: 40px;
}

.clear-dates-styles {
  padding-top: 30px;
  margin-top: -9px;
}

.icon-only-btn {
  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;
  padding: 0 !important;
}

.icon-only-btn:hover {
  background-color: transparent !important;
}

.icon-only-btn:disabled {
  opacity: 0.5;
}

.upload-style {
  padding: 32px;
  margin-top: 30px;
  font-family: "Poppins", sans-serif;
}

.v-icon {
  &::after {
    content: "";
    background-color: #6E57F8 !important;
  }
}

.alertbox {
  position: fixed;
  left: 70%;
  margin: 0 auto;
  z-index: 1;
}

.v-treeview-node--active {
  background-color: #9b9999 !important;
  color: black !important;
}

.rounded-border {
  margin-top: 10px;
  border-radius: 10px;
  padding: 16px;
  border: 1px solid #d1d1d1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.upload-title {
  margin-left: 35px;
  margin-top: 11px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
</style>

