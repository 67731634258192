<template>
  <v-dialog
    v-model="internalDialog"
    width="700px"
    id="delete-modal-dialog"
  >
    <v-card id="delete-card-dialog">
      <v-card-title class="dialog-title">
        Confirm Action
      </v-card-title>
      <v-card-text>
        <span id="text-delete-dialog" class="text">
          Are you sure to delete {{ currentUser }} user?
        </span>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          id="cancel-delete-btn"
          color="rgb(101,123,219)"
          text
          @click="cancel"
        >
          No
        </v-btn>
        <v-btn
          elevated
          class="btn-accept"
          id="confirm-delete-btn"
          dark
          @click="confirmDelete(currentId)"
        >
          Yes
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookies from 'js-cookie';
import { userServices } from '../services/userService';

export default {
  name: 'DeleteUserModal',
  props: {
    deleteDialog: {
      type: Boolean,
      default: false,
    },
    currentUser: String,
    currentId: Number,
    users_list: Array,
  },
  data() {
    return {
      authorization: Cookies.get('Authorization'),
      user: Cookies.get('User'),
      reloadUsers: [],
      internalDialog: this.deleteDialog
    };
  },
  watch: {
    deleteDialog(newVal) {
      this.internalDialog = newVal;
    },
    internalDialog(newVal) {
      this.$emit('update:deleteDialog', newVal);
    },
  },
  methods: {
    confirmDelete(userId) {
      userServices.deleteUser(this.authorization, userId)
        .then(() => {
          this.reloadUsers = this.users_list.filter((user) => user.id !== userId);
          this.$emit('userList', this.reloadUsers);
          this.closeDialog();
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
          this.closeDialog();
        });
    },
    cancel() {
      this.closeDialog();
    },
    closeDialog() {
      this.internalDialog = false;
      this.$emit('update:deleteDialog', false);
    },
  },
};
</script>
<style scoped>
  .dialog-title {
    font-weight: bold;
    color: white;
    text-align: center;
    background: linear-gradient(
      90deg,
      #6E57F8 0%,
      #14C5E0 100%
    ) !important;
  }
  .btn-accept {
    background-color: #6E57F8 !important;
    color: white !important;
  }
</style>