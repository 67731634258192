import { coreServices } from './coreService';
import { API_BASE_URL, defaultHeaders } from './apiConfig';

export const studyServices = {
  create(authorization, study) {
    const url = `${API_BASE_URL}/users/studies/`;
    const headers = defaultHeaders(authorization);
    const data = { name: study };
    return coreServices.post(url, data, headers);
  },

  list(authorization) {
    const url = `${API_BASE_URL}/users/studies/`;
    const headers = defaultHeaders(authorization);
    return coreServices.get(url, headers);
  },
};
