<template>
  <div>
    <app-header />
    <nav-bar />
    <v-container fluid class="container-styles">
      <div class="content">
        <v-card class="tableStyle" d-flex justify-center>
          <v-card-title>
            <strong>Documents</strong>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="documentos"
            :search="search"
            class="custom-table"
          >
          </v-data-table>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import AppHeader from '../components/AppHeader.vue';
import NavBar from '../components/NavBar.vue';
import { fileServices } from '../services/fileServices';
import { validationServices } from '../services/validationService';
import { ref } from 'vue';

export default {
  name: 'AuditTrail',
  components: {
    AppHeader,
    NavBar,
  },
  setup() {
    const isSidebarExpanded = ref(true);

    return {
      isSidebarExpanded,
    };
  },
  data() {
    return {
      authorization: Cookies.get('Authorization'),
      study: Cookies.get('Study'),
      search: '',
      headers: [
        {
          text: 'ID ',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'File name', value: 'file_name' },
        { text: 'Event description', value: 'event_description' },
        { text: 'Modifier', value: 'modifier' },
        { text: 'Validation', value: 'validation' },
        { text: 'Last Modified Date (Time zone GMT)', value: 'timestamp' },
      ],
      documentos: [],
    };
  },
  mounted() {
    this.getHistoric();
  },
  methods: {
    getHistoric() {
      const id = -1;
      fileServices.getHistoric(this.authorization, id)
        .then((response) => {
          this.auxHistoric(response);
        })
        .catch((error) => { console.log('error folder:', error); });
    },
    auxHistoric(response) {
      for (let i = 0; i < response.data.length; i += 1) {
        const object = {};
        const item = response.data[i];
        const timeString = item.timestamp;

        // Formatear la fecha
        const completeDate = timeString.split('T')[0];
        const year = completeDate.split('-')[0];
        const month = completeDate.split('-')[1];
        const day = completeDate.split('-')[2];
        const date = `${year}/${month}/${day}`;

        // Formatear la hora
        const timeAndOffset = timeString.split('T')[1];
        const hourTime = timeAndOffset.split(':')[0];
        const minuteTime = timeAndOffset.split(':')[1];
        const time1 = `${hourTime}:${minuteTime}`;

        // Combinar fecha y hora formateadas
        const formattedTimestamp = `${date} ${time1}`;

        // Llenar el objeto con los datos formateados
        object.id = item.id;
        object.timestamp = formattedTimestamp;
        object.event_description = item.event_description;
        object.file_name = item.file_name;
        object.modifier = item.modifier;
        object.validation = validationServices.getValidatedIconFromBool(item.validation);

        // Agregar el objeto a la lista de documentos
        this.documentos.push(object);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$sidebar-width: 40px;
$sidebar-expanded-width: 170px !important;
$transition-time: 0.3s;

.content {
  margin-top: 30px;
  transition: margin-left $transition-time ease;
}

.tableStyle {
  padding: 32px;
  font-family: 'Poppins', sans-serif;
  margin-right: 10px;
}
.custom-table {
  max-width: 90%;
  width: 90%;
}
</style>

