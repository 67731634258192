<template>
  <v-app>
    <v-main>
      <div class="split left">
        <div class="centered">
          <img
            alt="ClinfAI logo"
            :src="require('@/assets/img/clinfai.png')"
            class="responsive-img"
          />
        </div>
      </div>
      <div class="split right">
        <v-alert
          class="alertbox-reset-password"
          v-if="alert"
          :value="alert"
          type="error"
          transition="scale-transition"
        >{{ error }}</v-alert>
        <v-container fluid fill-height>
          <v-row justify="center">
            <v-col cols="10" sm="8" md="8">
              <v-form>
                <div class="div-avatar-style align-center">
                  <v-avatar color="#6E57F8" class="change-font" size="100">
                    <v-icon size="50" color="white">mdi-key-variant</v-icon>
                  </v-avatar>
                </div>
                <div class="align-center">
                  <span class="forgot-pass-title">
                    Forgot Your Password?
                  </span>
                </div>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="email"
                  label="E-mail"
                  class="rounded-pill"
                  :rules=emailRules
                  id="email"
                  placeholder="Email"
                  prepend-icon="mdi-account"
                  required
                  color="#6E57F8"
                ></v-text-field>
              </v-form>
              <div class="align-center muted-text-wrapper">
                <span class="muted-text">
                  Enter your primary SermesCRO email address
                  and we will send you a reset.
                </span>
              </div>
              <v-spacer></v-spacer>
              <v-btn
                rounded
                size="large"
                color="#6E57F8"
                class="white--text btn-narrow"
                font-family="Poppins"
                @click="resetPassword"
                :disabled="formValid"
              >
                Reset Password
              </v-btn>
              <br />
              <div class="text-forgot-pass">
                Already have an account?
                <router-link to="/login">
                  Sign in
                </router-link>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-main>
  </v-app>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { userServices } from '../services/userService';

const email = ref('');
const error = ref('');
const alert = ref(false);
const router = useRouter();
const emailRules = [(v) => !!v || 'E-mail is required', (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'];

const formValid = computed(() => email.value === '');

const resetPassword = async () => {
  const formData = new FormData();
  formData.append('email', email.value);
  try {
    await userServices.resetPassword(formData);
    router.push('/checkEmail');
  } catch (err) {
    error.value = 'Failed to send reset link. Please try again.';
    alert.value = true;
    setTimeout(() => {
      alert.value = false;
      error.value = '';
    }, 3000);
  }
};
</script>

<style scoped>
body {
  font-family: 'Poppins', sans-serif;
}

.alertbox-reset-password {
    position: fixed;
    left: 75%;
    margin: 0 auto;
    z-index: 1;
    margin-bottom: 500px;
  }

.split.right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.align-center {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.div-avatar-style {
  display: flex;
  justify-content: center;
}

.muted-text-wrapper {
  max-width: 300px;
  margin: 0 auto;
}

.muted-text {
  color: #000000;
  display: block;
  margin-top: 7px;
  margin-bottom: 12px;
  font-size: 18px;
}
.forgot-pass-title {
  color: #6E57F8;
  font-size: 32px;
  margin-bottom: 20px !important;
}
.btn-narrow {
  max-width: 200px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 10px;
}
.split {
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.left {
  left: 0;
  background: linear-gradient(90deg, #6e57f8 0%, #14c5e0 100%);
}

.right {
  right: 0;
  background-color: white;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.responsive-img {
    max-width: 100%;
    width: auto;
    height: auto;
  }


.text-forgot-pass {
  text-align: center !important;
}

@media (max-width: 768px) {
  .responsive-img {
    min-width: 90%;
  }
}

@media (max-width: 480px) {
  .responsive-img {
    min-width: 100%;
  }
}
</style>
