import { coreServices } from './coreService';
import { API_BASE_URL, defaultHeaders } from './apiConfig';

export const commentServices = {
  listByDocumentId(authorization, documentId) {
    const url = `${API_BASE_URL}/files/comments/document/${documentId}/`;
    const headers = defaultHeaders(authorization);
    return coreServices.get(url, headers);
  },

  create(authorization, comment) {
    const url = `${API_BASE_URL}/files/comments/`;
    const headers = defaultHeaders(authorization);
    return coreServices.post(url, comment, headers);
  },

  update(authorization, comment) {
    const url = `${API_BASE_URL}/files/comments/`;
    const headers = defaultHeaders(authorization);
    return coreServices.put(url, comment, headers);
  },

  delete(authorization, id) {
    const url = `${API_BASE_URL}/files/comments/${id}/`;
    const headers = defaultHeaders(authorization);
    return coreServices.delete(url, headers);
  },
};
