import axios from 'axios';

export const coreServices = {
  async get(url, header, params) {
    const res = await axios.get(url, { headers: header, params });
    return res;
  },
  async get_blob(url, header, params) {
    const res = await axios.get(url, { headers: header, params, responseType: 'blob' });
    return res;
  },
  async post(url, data, header) {
    const res = await axios.post(url, data, { headers: header });
    return res;
  },
  async put(url, data, header) {
    const res = await axios.put(url, data, { headers: header });
    return res;
  },
  async patch(url, data, header) {
    return axios.patch(url, data, { headers: header })
      .then((response) => response)
      .catch((error) => {
        throw error.response;
      });
  },
  async delete(url, header) {
    const res = await axios.delete(url, { headers: header });
    return res;
  },
  async post_with_errors(url, data, header) {
    return axios.post(url, data, { headers: header })
      .then((response) => response)
      .catch((error) => {
        throw error.response;
      });
  },
};
