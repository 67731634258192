<template>
  <div id="app-header-div">
    <v-app-bar
      id="app-header-bar"
      app
      class="app-bar"
      flat
      extension-height="100%"
    >
      <router-link :to="'/home'">
        <img
          :src="require('@/assets/img/clinfai.png')"
          alt=""
          height="64px"
          width="166px"
          class="logo-large"
        />
      </router-link>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template #activator="{ props }">
          <v-btn
            v-show="role === 'Updater'"
            id="upload-file-app-header-btn"
            fab
            dark
            color="white"
            plain
            to="/library/uploadFile"
            v-bind="props"
          >
            <v-icon class="icons-style" size="23">
              mdi-upload
            </v-icon>
          </v-btn>
        </template>
        <span id="upload-text-app-header">Upload</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ props }">
          <v-btn
            id="user-profile-app-header-btn"
            plain
            fab
            dark
            color="white"
            class="registered-user"
            to="/home/userProfile"
            v-bind="props"
          >
            <v-icon size="23">
              mdi-account-circle
            </v-icon>
          </v-btn>
        </template>
        <span id="name-text-app-header">{{ register_name }} {{ register_last_name }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ props }">
          <v-btn
            id="logout-app-header-btn"
            plain
            fab
            dark
            color="white"
            class="icons-style"
            @click="logout"
            v-bind="props"
          >
            <v-icon size="23">
              mdi-logout
            </v-icon>
          </v-btn>
        </template>
        <span id="logout-text-app-header">Logout</span>
      </v-tooltip>
    </v-app-bar>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useRouter } from 'vue-router';
import { userServices } from '../services/userService';
import { logoutServices } from '../services/logoutService';

export default {
  name: 'AppHeader',
  setup() {
    const register_name = ref(' ');
    const register_last_name = ref(null);
    const role = ref('');
    const uploadEnable = ref(false);
    const authorization = Cookies.get('Authorization');
    const router = useRouter();

    const checkUserData = () => {
      userServices
        .checkUserData(authorization)
        .then((response) => {
          register_name.value = response.data.first_name;
          register_last_name.value = response.data.last_name;
          role.value = response.data.role;
          if (role.value === 'Admin') {
            uploadEnable.value = true;
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    };

    const logout = () => {
      axios.defaults.withCredentials = false;
      logoutServices
        .logout(authorization)
        .then((res) => {
          if (res.request.status === 200) {
            document.cookie.split(';').forEach((c) => {
              document.cookie = `${c.trim().split('=')[0]}=; expires=Thu, 01-Jan-70 00:00:01 GMT;`;
            });
            localStorage.clear();
            router.push('/');
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    };

    onMounted(() => {
      const storedRole = localStorage.getItem('role');
      if (!storedRole) {
        checkUserData();
      } else {
        role.value = storedRole;
        register_name.value = localStorage.getItem('userRegisterName');
        register_last_name.value = localStorage.getItem('UserRegisterLastName');
        if (role.value === 'Admin') {
          uploadEnable.value = true;
        }
      }
    });

    return {
      register_name,
      register_last_name,
      role,
      uploadEnable,
      logout,
    };
  },
};
</script>

<style lang="scss" scoped>
$primary-gradient: linear-gradient(90deg, #6E57F8 0%, #14C5E0 100%);
$font-family: 'Poppins', 'Futura', sans-serif;
$icon-padding: 0 10px;

.app-bar {
  background: $primary-gradient !important;
  font-family: $font-family;
}

.logo-large {
  margin-top: 9px;
}

.content {
  padding: 40px 40px !important;
}

.expand-bar {
  border-radius: 4px !important;
  max-width: fit-content !important;
}

.side-container {
  padding: 0 !important;
}

.icons-style {
  padding: $icon-padding;
}

</style>
