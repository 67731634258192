<template>
  <div>
      <app-header />
      <div>
      <div class="edition"></div>
        <accept-terms-content/>
      </div>
  </div>
</template>
<script>
import AppHeader from '../components/AppHeader.vue';
import AcceptTermsContent from '../components/AcceptTermsContent.vue';

export default {
  name: 'TermsView',
  components: {
    AppHeader,
    AcceptTermsContent,
  },
  data() {
    return {
      items: [
        { title: 'Home', icon: 'mdi-home-city' },
        { title: 'My Account', icon: 'mdi-account' },
        { title: 'Users', icon: 'mdi-account-group-outline' },
      ],
    };
  },
};
</script>
<style>
  .v-main__wrap {
  background-color: #F7F7F7 !important;
  }
  .edition {
  font-family: 'Poppins', sans-serif;
  }
</style>
