<template>
  <v-dialog v-model="localShowModal" max-width="80%" persistent>
    <v-card class="modal-content">
      <v-card-title class="custom-title">
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-card-subtitle>
        <v-alert
          v-if="alert"
          :value="alert"
          type="error"
          transition="scale-transition"
        >
          {{ error }}
        </v-alert>
      </v-card-subtitle>
      <v-card-text>
        <v-form>
          <v-container>
            <v-row>
              <v-col cols="12" sm="10" class="mx-auto">
                <v-text-field
                  v-model="localEmail"
                  label="E-mail"
                  color="#6E57F8"
                  prepend-inner-icon="mdi-email-outline"
                  :rules="emailRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="10" class="mx-auto">
                <v-text-field
                  v-model="localPassword"
                  prepend-inner-icon="mdi-lock-outline"
                  type="password"
                  label="Password"
                  color="#6E57F8"
                  :rules="passwordRules"
                  @keyup.enter="confirmUserPass"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <!-- Botón de cancelar -->
        <v-btn
          text
          class="btn-cancel"
          @click="closeModal"
        >
          Cancel
        </v-btn>
        <!-- Botón de aceptar -->
        <v-btn
          elevated
          class="btn-accept"
          @click="confirmUserPass"
          :disabled="formValid"
        >
          {{ buttonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, computed, watch } from 'vue';
import Cookies from 'js-cookie';
import { useRouter } from "vue-router";
import { userServices } from "../services/userService";
import { logoutServices } from "../services/logoutService";
import { cookieExpiration } from '../utils/cookieExpiration';
export default {
  name: 'ConfirmationModal',
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      default: null
    },
    buttonText: {
      type: String,
      default: 'Accept'
    },
    email: {
      type: String,
      default: ''
    },
    password: {
      type: String,
      default: ''
    },
    onSuccess: {
      type: Function,
      default: null
    }
  },
  setup(props, { emit }) {
    const localShowModal = ref(props.showModal);
    const localEmail = ref(props.email);
    const localPassword = ref(props.password);
    const alert = ref(false);
    const error = ref('');
    const study = Cookies.get('study');
    const router = useRouter();
    const authorization = Cookies.get("Authorization");

    const emailRules = [v => /@/.test(v) || 'E-mail must contain an @ symbol'];
    const passwordRules = [v => !!v || 'Password is required'];

    const formValid = computed(() => !localEmail.value || !localPassword.value);

    async function confirmUserPass() {
      try {
        const data = {
          email: localEmail.value,
          password: localPassword.value,
          study_name: study
        };

        const response = await userServices.Login(data);
        
        if (response.status === 200) {
          const expiration = cookieExpiration();
          document.cookie = `Authorization=${Cookies.get('Authorization')}; expires=${expiration.toUTCString()}`;
          document.cookie = `user=${localEmail.value}; expires=${expiration.toUTCString()}`;
          document.cookie = `study=${study}; expires=${expiration.toUTCString()}`;

          // Ejecutar la acción adicional si se proporciona
          if (props.onSuccess) {
            props.onSuccess();
          }
          emit('close');
        } else {
          setTimeout(() => {
            alert.value = false;
            error.value = ""
            logoutServices.logout(authorization);
            router.push("/");
          }, 3000);
          throw new Error('Invalid Credentials');
        }
      } catch (err) {
        error.value = 'An error occurred';
        alert.value = true;
        setTimeout(() => {
          alert.value = false;
          error.value = "";
          logoutServices.logout(authorization);
          router.push("/");
        }, 3000);
      }
    }

    function closeModal() {
      emit('close');
      router.push("/library");
    }

    watch(() => props.showModal, (newVal) => {
      localShowModal.value = newVal;
    });

    watch(() => props.email, (newVal) => {
      localEmail.value = newVal;
    });

    watch(() => props.password, (newVal) => {
      localPassword.value = newVal;
    });

    return {
      localShowModal,
      localEmail,
      localPassword,
      alert,
      error,
      formValid,
      emailRules,
      passwordRules,
      confirmUserPass,
      closeModal
    };
  }
};
</script>

<style scoped>
  .headline {
    font-family: 'Poppins', sans-serif;
  }
  .btn-accept {
    background-color: #6E57F8 !important;
    color: white !important;
  }

  .btn-cancel {
    color: #6E57F8 !important;
  }
  .v-dialog__container {
    display: block;
    position: fixed;
    z-index: 1;
    padding-top: 80px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .custom-title {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    margin-bottom: 200px;
  }
</style>
