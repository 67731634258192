import { createVuetify } from 'vuetify';
import 'vuetify/styles';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

// const myCustomLightTheme = {
//   dark: false,
//   colors: {
//     primary: '#4A90E2',
//     secondary: '#50E3C2',
//     accent: '#9B9B9B',
//     error: '#D0021B',
//     info: '#2AB3BA',
//     success: '#7ED321',
//     warning: '#F5A623',
//   },
// };

export default createVuetify({
  components,
  directives,
});
