<script>
  import { toast } from "vue3-toastify";
  import "vue3-toastify/dist/index.css";
  
  export default {
    name: "AlertNotification",
    props: {
      text: {
        type: String,
        required: true,
      },
      type: {
        type: String,
        required: true,
        default: "error",
        validator(value) {
          return ["info", "success", "warning", "error", "default"].includes(value);
        },
      },
      theme: {
        type: String,
        default: "colored",
      },
      hideProgressBar: {
        type: Boolean,
        default: true,
      },
      transition: {
        type: String,
        default: "slide",
      },
      dangerouslyHTMLString: {
        type: Boolean,
        default: true,
      },
    },
    methods: {
      showToast() {
        toast(this.text, {
          theme: this.theme,
          type: this.type,
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: this.hideProgressBar,
          transition: this.transition,
          dangerouslyHTMLString: this.dangerouslyHTMLString,
        });
      },
    },
    mounted() {
      this.showToast();
    },
  };
</script>

