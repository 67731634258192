<template>
  <div class="main-container">
    <app-header />
    <nav-bar />
    <v-container fluid class="container-styles">
      <v-card id="adminview-form" class="admin-card-style">
        <v-toolbar
          class="mb-6 list-users-title"
          color="#6E57F8"
          light
          extended
        >
          <v-toolbar-title class="white--text user-list-header">
            <v-card-title class="pt-12">
              <span class="user-list-title-text">List of users</span>
            </v-card-title>
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <div class="add-user-btn">
          <add-user-modal
          :studiesList="studiesList"
          :users="users"
          :regionsList="regionsList"
          @newUserData="newUser"
        />
        <v-spacer></v-spacer>

        </div>
        <div v-for="user in users" :key="user.id" fluid>
          <v-row class="form-style">
            <v-col cols="12" md="2">
              <v-text-field
                :id="`email-admin-view-${user.id}`"
                v-model="user.email"
                label="E-mail"
                color="#6E57F8"
                readonly
              />
            </v-col>
            <v-col cols="6" md="1">
              <v-text-field
                :id="`first-name-admin-view-${user.id}`"
                v-model="user.first_name"
                label="First name"
                color="#6E57F8"
                readonly
              />
            </v-col>
            <v-col cols="6" md="1">
              <v-text-field
                :id="`last-name-admin-view-${user.id}`"
                v-model="user.last_name"
                readonly
                color="#6E57F8"
                label="Last name"
              />
            </v-col>
            <v-col cols="6" md="1">
              <v-text-field
                v-model="user.company"
                readonly
                color="#6E57F8"
                label="Company"
              />
            </v-col>
            <v-col cols="6" md="1">
              <v-text-field
                v-model="user.company_role"
                readonly
                color="#6E57F8"
                label="Company role"
              />
            </v-col>
            <v-col cols="6" md="1">
              <v-text-field
                :id="`study-admin-view-${user.id}`"
                v-model="user.study"
                label="Study"
                color="#6E57F8"
                readonly
              />
            </v-col>
            <v-col cols="6" md="1">
              <v-text-field
                :id="`role-admin-view-${user.id}`"
                v-model="user.role"
                label="Role"
                color="#6E57F8"
                readonly
              />
            </v-col>
            <v-col cols="6" md="1">
              <v-text-field
                v-model="user.region"
                readonly
                color="#6E57F8"
                label="Region"
              />
            </v-col>
            <v-col cols="6" md="1">
              <v-text-field
                v-model="user.zip"
                readonly
                color="#6E57F8"
                label="Zip"
              />
            </v-col>
            <v-col cols="12" md="2" class="d-flex align-center">
              <v-btn
                small
                fab
                class="mr-1 ml-2"
                color="#14C5E0"
                @click="getUserChange(user)"
              >
                <v-icon color="white">mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                small
                fab
                class="mr-1 ml-2"
                color="error"
                dark
                @click="deleteUser(user.id, user.email)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <template v-if="Object.keys(userSelected).length !== 0">
          <edit-user-modal
            :updateDialog="updateDialog"
            @cancel="updateDialog = false"
            @confirmEdit="updateDialog = false"
            :studiesList="studiesList"
            :userSelected="userSelected"
            :users_list="users_list"
            :currentId="currentId"
            :regionsList="regionsList"
            @updateUsers="userChangedList"
            @userChange="getUserChange"
            @updateList="checkUserUpdate"
          />
        </template>
        <template v-if="currentId !== null">
          <delete-user-modal
            :deleteDialog="deleteDialog"
            @update:deleteDialog="deleteDialog = $event"
            @cancel="deleteDialog = false"
            @confirm="deleteDialog = false"
            :users_list="users_list"
            :currentUser="currentUser"
            :currentId="currentId"
            @userList="checkUserList"
          />
        </template>
        <v-snackbar v-model="snackbar" :timeout="timeout">
          User deleted successfully.
          <template v-slot:action="{ attrs }">
            <v-btn color="#6E57F8" text v-bind="attrs" @click="snackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
        <v-overlay :value="charging">
          <v-progress-circular :size="size" :color="color" indeterminate />
        </v-overlay>
      </v-card>
    </v-container>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate :color="color" :size="size" />
    </v-overlay>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import AppHeader from '../components/AppHeader.vue';
import NavBar from '../components/NavBar.vue';
import AddUserModal from '../components/AddUserModal.vue';
import DeleteUserModal from '../components/DeleteUserModal.vue';
import EditUserModal from '../components/EditUserModal.vue';
import { userServices } from '../services/userService';
import { studyServices } from '../services/studyService';

export default {
  name: 'AdminView',
  components: {
    AppHeader,
    NavBar,
    AddUserModal,
    DeleteUserModal,
    EditUserModal,
  },
  data() {
    return {
      first_name: ' ',
      last_name: ' ',
      roleUser: '',
      email: '',
      study_name: ' ',
      study_id: ' ',
      users: [],
      authorization: Cookies.get('Authorization'),
      study: Cookies.get('study'),
      user: Cookies.get('User'),
      role: Cookies.get('role'),
      users_list: [],
      user_id: '',
      deleteDialog: false,
      showAddUserDialog: true,
      updateDialog: false,
      snackbar: false,
      timeout: 2000,
      studiesList: [],
      loading: false,
      charging: false,
      size: '45',
      color: '#6E57F8',
      currentUser: ' ',
      currentId: null,
      currentStudyList: [],
      userSelected: {},
      regionsList: [
        'Andalucía', 'Aragón', 'Asturias', 'Canarias',
        'Cantabria', 'Castilla-La Mancha', 'Castilla y León', 'Cataluña',
        'Comunidad Valenciana', 'Extremadura', 'Galicia',
        'Islas Baleares', 'La Rioja', 'Madrid', 'Murcia',
        'Navarra', 'País Vasco',
      ],
    };
  },
  mounted() {
    const role = localStorage.getItem('role');
    if (role === undefined) {
      this.checkUserRole();
    } else {
      this.role = role;
    }
    this.getUsersList();
    this.getStudies();
  },
  methods: {
    deleteUser(userId, userEmail) {
      this.deleteDialog = true;
      this.currentUser = userEmail;
      this.currentId = userId;
      this.users_list = this.users;
    },
    getUserChange(userData) {
      userServices.getUserChange(this.authorization, userData)
        .then(() => {
          this.updateDialog = true;
          this.users_list = this.users;
          this.userSelected = userData;
          this.currentId = userData.id;
          this.userSelected.studies = userData.studies.map((i, e) => {
            return {
              name: userData.study[e], id: userData.studies[e],
            };
          });
          this.userSelected.regions = [userData.region];
        });
      this.userSelected = {};
    },
    checkUserRole() {
      userServices.checkUserData(this.authorization)
        .then((response) => {
          this.role = response.data.role;
        }).catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    },
    getUsersList() {
      userServices.getUsersList(this.authorization, this.study)
        .then((response) => {
          this.users = response.data;
          const selectedList = this.role === 'Superadmin' ? this.users : this.users.filter((user) => user.role !== 'Superadmin');
          this.users = selectedList;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    },
    getStudies() {
      studyServices.list(this.authorization)
        .then((response) => {
          this.studiesList = response.data.map((study) => ({
            id: study.id,
            name: study.name,
          }));
        })
        .catch((error) => {
        // eslint-disable-next-line no-console
          console.error(error);
        });

    },
    checkUserList(userList) {
      this.users = userList;
      this.snackbar = true;
    },
    checkUserUpdate() {
      this.charging = true;
      setTimeout(() => {
        this.charging = false;
      }, 2000);
    },
    userChangedList(updateUsers) {
      this.users = updateUsers;
      this.userSelected = {};
    },
    newUser(newUserData) {
      this.users.push(newUserData);
      this.showAddUserDialog = true;
      setTimeout(() => {
        this.showAddUserDialog = false;
      }, 2000);
    },
  },
};
</script>
<style scoped>
.admin-content {
  flex: 1;
  padding: 20px;
}

.admin-card-style {
  margin-top: 30px;
  font-family: 'Poppins', sans-serif;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding-bottom: 30px;
}

.add-user-btn {
  margin-top: 7px;
  margin-bottom: 20px;
  margin-right: 70px;
  display: grid;
  justify-content: end; /* Alinea el contenido a la derecha */
}

.list-users-title {
  background: linear-gradient(90deg, #6E57F8 0%, #14C5E0 100%);
  border-radius: 8px 8px 0 0; /* Redondeo solo en las esquinas superiores */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.user-list-header {
  font-family: 'Poppins', sans-serif;
  margin-right: 5px;
  margin-left: 5px;
  color: #FFFFFF;
}

.user-list-title-text {
  font-size: 1.5em;
}

.form-style {
  padding-left: 24px;
}

.edit-user-button {
  color: "#6E57F8";
}

@media screen and (max-width: 470px) {
  .admin-card-style {
    margin-left: 0;
    max-width: 100%;
  }

  .form-style {
    padding-left: 24px;
  }

  .admin-content {
    padding: 10px 34px !important;
  }
}
</style>
