import { createRouter, createWebHistory } from 'vue-router';

import HomeView from '../views/HomeView.vue';
import LoginView from '../views/LoginView.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import AuditTrail from '../views/AuditTrail.vue';
import LibraryView from '../views/LibraryView.vue';
import EditStudy from '../views/EditStudy.vue';
import UploadFile from '../views/UploadFile.vue';
import CheckEmail from '../views/CheckEmail.vue';
import UserProfile from '../views/UserProfile.vue';
import AdminView from '../views/AdminView.vue';
import AcceptTermsView from '../views/AcceptTermsView.vue';
import { cookieAsObject } from '../utils/cookieToObject';
import { userServices } from '../services/userService';

async function checkAuth(to, from, next, allowedRoles) {
  try {
    const cookieObject = cookieAsObject(document.cookie);
    const authorization = cookieObject.Authorization;

    if (!authorization) {
      return next({ name: 'LoginView' });
    }

    const response = await userServices.checkUserData(authorization);

    if (!response.data.check_read_all_documents) {
      if (to.name !== 'AcceptTerms') {
        return next({ name: 'AcceptTerms' });
      }
      return next();
    }

    if (allowedRoles && !allowedRoles.includes(response.data.role)) {
      if (to.name !== 'HomeView') {
        return next({ name: 'HomeView' });
      }
      return next();
    }

    return next();
  } catch (error) {
    console.error(error);
    return next({ name: 'ErrorPage' });
  }
}

const requireCookie = (to, from, next) => checkAuth(to, from, next);
const adminUsers = (to, from, next) => checkAuth(to, from, next, ['Admin', 'Superadmin']);
const clientUsers = (to, from, next) => checkAuth(to, from, next, ['Viewer', 'Updater', 'Client']);
const updaterUsers = (to, from, next) => checkAuth(to, from, next, ['Updater']);

const routes = [
  {
    path: '/home',
    name: 'HomeView',
    component: HomeView,
    beforeEnter: requireCookie,
  },
  {
    path: '/home/userProfile',
    name: 'UserProfile',
    component: UserProfile,
    beforeEnter: requireCookie,
  },
  {
    path: '/',
    name: 'HomeLogin',
    component: LoginView,
  },
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView,
  },
  {
    path: '/forgotpassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/checkEmail',
    name: 'CheckEmail',
    component: CheckEmail,
  },
  {
    path: '/library',
    name: 'LibraryView',
    component: LibraryView,
    beforeEnter: clientUsers,
  },
  {
    path: '/library/document/:id/edit',
    name: 'EditStudy',
    component: EditStudy,
    beforeEnter: clientUsers,
  },
  {
    path: '/library/uploadFile',
    name: 'UploadFile',
    component: UploadFile,
    beforeEnter: updaterUsers,
  },
  {
    path: '/audit_trail',
    name: 'AuditTrail',
    component: AuditTrail,
    beforeEnter: clientUsers,
  },
  {
    path: '/admin',
    name: 'AdminView',
    component: AdminView,
    beforeEnter: adminUsers,
  },
  {
    path: '/accept_terms',
    name: 'AcceptTerms',
    component: AcceptTermsView,
    beforeEnter: async (to, from, next) => {
      try {
        const cookieObject = cookieAsObject(document.cookie);
        const authorization = cookieObject.Authorization;
        if (!authorization) {
          return next();
        }

        const response = await userServices.checkUserData(authorization);

        if (response.data.check_read_all_documents) {
          return next({ name: 'HomeView' });
        }

        return next();
      } catch (error) {
        console.error(error);
        return next({ name: 'ErrorPage' });
      }
    },
  },
  {
    path: '/about',
    name: 'AboutView',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes, // No necesitas `base: process.env.BASE_URL` en Vue 3
});

export default router;
