<template>
  <v-navigation-drawer
    permanent
    expand-on-hover
    rail
    id="navbar-router-link"
    class="sidebar"
    :width="220"
    mobile-breakpoint="xs"
  >
    <v-list density="compact" nav v-for="route in tabs" :key="route.tabName">
      <v-list-item
        :key="route.tabName"
        :to="route.path"
        :prepend-icon="route.icon"
        :title="route.tabName"
      >
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import Cookies from 'js-cookie';
import { userServices } from '../services/userService';

const tabs = ref([
  { tabName: 'HOME', path: '/home', icon: 'mdi-home' },
]);
const authorization = Cookies.get('Authorization');
const role = ref('');

const checkUserData = async () => {
  try {
    const response = await userServices.checkUserData(authorization);
    role.value = response.data.role;
    tabs.value = generateTabs(tabs.value, role.value);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

const generateTabs = (tabs, role) => {
  const tabAdmin = {
    tabName: 'ADMIN',
    path: '/admin',
    icon: 'mdi-account-supervisor',
  };
  const tabLibrary = {
    tabName: 'LIBRARY',
    path: '/library',
    icon: 'mdi-book-open-variant',
  };
  const tabAudit = {
    tabName: 'AUDIT TRAIL',
    path: '/audit_trail',
    icon: 'mdi-folder',
  };

  if (role === 'Admin' || role === 'Superadmin') {
    tabs.push(tabAdmin);
  } else {
    tabs.push(tabLibrary);
    tabs.push(tabAudit);
  }

  return tabs;
};

onMounted(() => {
  const storedRole = localStorage.getItem('role');
  if (!storedRole) {
    checkUserData();
  } else {
    role.value = storedRole;
    tabs.value = generateTabs(tabs.value, role.value);
  }
});
</script>
<style lang="scss" scoped>
.sidebar {
  margin: 0;
  padding: 0;
  background-color: #f7f7f7 !important;
  position: absolute;
  z-index: 9999; /* Asegura que el drawer esté sobre otros elementos */
  height: 100%;
  overflow: auto;
  font-family: 'Poppins', sans-serif;

  &:hover {
    width: 180px; // Ancho más reducido cuando se expande
    transition: width 0.3s ease; // Suaviza la transición
  }

  a {
    font-size: 13px;
    color: black;
    text-decoration: none;

    &:hover {
      color: white !important;
      background: #6E57F8; // Color de fondo al pasar el cursor
    }

    &:hover:not(.active) {
      background: #6E57F8; // Color de fondo al pasar el cursor
      color: white; // Color del texto al pasar el cursor
    }
  }
}

.content {
  margin-left: auto;
  background-color: #F7F7F7;
  padding: 1px 16px;
  height: 100%;
}

@media screen and (max-width: 700px) {
  .sidebar {
    display: flex;
    width: auto;
    height: 100%;
    position: fixed;
    z-index: 999;
    justify-content: space-between;
    font-size: 0;
  }

  .content {
    margin-left: auto;
    padding-right: 12px !important;
    padding-top: 1px !important;
    height: 100%;
  }

  .v-btn {
    padding: 0 !important;
  }
}

@media screen and (max-width: 450px) {
  .sidebar {
    display: flex;
    width: auto;
    height: 100%;
    position: fixed;
    z-index: 999;
    justify-content: space-between;
    font-size: 0;
  }

  .tree-container {
    margin-left: 40px;
  }

  .icons-style {
    padding: 0 !important;
  }

  .v-btn:not(.v-btn--round).v-size--default {
    min-width: 40px !important;
    padding: 5px !important;
  }

  .logo-large {
    display: block;
  }

  .logo-small {
    display: none;
  }
}

@media screen and (min-width: 1020px) {
  .sidebar {
    height: 100%;
    justify-content: space-between;
    position: fixed;
    z-index: 999;
  }
}

@media screen and (max-width: 1279px) {
  .sidebar {
    display: flex !important;
    width: 60px;
  }
}

@media screen and (max-width: 427px) {
  .sidebar a {
    text-align: center;
    float: none;
    padding: 11px;
  }

  .sidebar {
    height: 100%;
  }

  .appbar-style {
    padding: 0 !important;
    min-width: 26px !important;
  }

  .logo-large {
    display: none;
  }

  .logo-small {
    display: block;
    max-width: 20px;
    margin-right: 16px;
  }

  .v-navigation-drawer {
    display: flex;
    flex-direction: column;
  }
}
</style>
