import { coreServices } from './coreService';

import { API_BASE_URL, defaultHeaders } from './apiConfig';

export const validationServices = {

  listByDocumentId(authorization, documentId) {
    const url = `${API_BASE_URL}/files/validations/document/${documentId}/`;
    const headers = defaultHeaders(authorization);
    return coreServices.get(url, headers);
  },

  create(authorization, validation) {
    const url = `${API_BASE_URL}/files/validations/`;
    const headers = defaultHeaders(authorization);
    return coreServices.post(url, validation, headers);
  },

  update(authorization, validation, id) {
    const url = `${API_BASE_URL}/files/validations/${id}/`;
    const headers = defaultHeaders(authorization);
    return coreServices.put(url, validation, headers);
  },

  delete(authorization, id) {
    const url = `${API_BASE_URL}/files/validations/${id}/`;
    const headers = defaultHeaders(authorization);
    return coreServices.delete(url, headers);
  },

  getValidatedIcon(validationObj) {
    if (!validationObj) return '';
    return validationObj.validated ? '✔️' : '❌';
  },

  getValidatedIconFromBool(validation) {
    if (validation == null) return '❌';
    return validation ? '✔️' : '❌';
  },
};
