const API_BASE_URL = process.env.VUE_APP_BASE_URL;
const API_URL_REST_AUTH = process.env.VUE_APP_REST_AUTH_API_URL;
const API_FILE_URL = process.env.VUE_APP_UPLOAD_FILE_API_URL;

const defaultHeaders = (authorization) => ({
  Authorization: `Token ${authorization}`,
  'Content-Type': 'application/json',
});

export {
  API_BASE_URL,
  API_URL_REST_AUTH,
  API_FILE_URL,
  defaultHeaders,
};
