import { coreServices } from './coreService';
import { API_FILE_URL, defaultHeaders } from './apiConfig';

export const fileServices = {
  getHistoric(authorization) {
    const url = `${API_FILE_URL}/historic/`;
    return coreServices.get(url, defaultHeaders(authorization));
  },
  fileInformation(authorization, internalFileId) {
    const url = `${API_FILE_URL}/documents/${internalFileId}/`;
    return coreServices.get(url, defaultHeaders(authorization));
  },
  getItems(authorization, study) {
    const url = `${API_FILE_URL}/folders/`;
    const params = { study_id: study };
    return coreServices.get(url, defaultHeaders(authorization), params);
  },
  getInformation(authorization, folderId) {
    const url = `${API_FILE_URL}/folders/${folderId}/`;
    return coreServices.get(url, defaultHeaders(authorization));
  },
  addFolder(authorization, folderId, folderName) {
    const url = `${API_FILE_URL}/folders/`;
    const data = { folderId, folderName };
    return coreServices.post(url, data, defaultHeaders(authorization));
  },
  saveHistoric(authorization, fileId) {
    const url = `${API_FILE_URL}/historic/`;
    const data = { id: fileId };
    return coreServices.post(url, data, defaultHeaders(authorization));
  },
  uploadFile(authorization, formData) {
    const url = `${API_FILE_URL}/upload/`;
    const headers = {
      Authorization: `Token ${authorization}`,
      'Content-Type': 'multipart/form-data',
    };
    return coreServices.post_with_errors(url, formData, headers);
  },
  deleteFile(authorization, internalFileId) {
    const url = `${API_FILE_URL}/documents/${internalFileId}/`;
    return coreServices.delete(url, defaultHeaders(authorization));
  },
  updateFile(authorization, internalFileId, data) {
    const url = `${API_FILE_URL}/documents/${internalFileId}/`;
    return coreServices.patch(url, data, defaultHeaders(authorization));
  },
  getMetadataFiles(authorization, folderId) {
    const url = `${API_FILE_URL}/folders/metadata/`;
    const data = { folder_id: folderId };
    return coreServices.get(url, defaultHeaders(authorization), data);
  },
  getPDF(authorization, internalFileId) {
    const url = `${API_FILE_URL}/documents/${internalFileId}/attachments/`;

    return coreServices.get_blob(url, defaultHeaders(authorization));
  },
};
