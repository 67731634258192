<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    isInactive: false,
    userActivityThrottlerTimeout: null,
    userActivityTimeout: null,
  }),
  methods: {
    activateActivityTracker() {
      window.addEventListener('mousemove', this.userActivityThrottler);
      window.addEventListener('scroll', this.userActivityThrottler);
      window.addEventListener('keydown', this.userActivityThrottler);
      window.addEventListener('resize', this.userActivityThrottler);
    },
    deactivateActivityTracker() {
      window.removeEventListener('mousemove', this.userActivityThrottler);
      window.removeEventListener('scroll', this.userActivityThrottler);
      window.removeEventListener('keydown', this.userActivityThrottler);
      window.removeEventListener('resize', this.userActivityThrottler);
    },
    resetUserActivityTimeout() {
      clearTimeout(this.userActivityTimeout);
      this.userActivityTimeout = setTimeout(() => {
        this.userActivityThrottler();
        this.inactiveUserAction();
      }, 300000);
    },
    userActivityThrottler() {
      if (this.isInactive) {
        this.isInactive = false;
      }
      if (!this.userActivityThrottlerTimeout) {
        this.userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout();
          clearTimeout(this.userActivityThrottlerTimeout);
          this.userActivityThrottlerTimeout = null;
        }, 1000);
      }
    },
    inactiveUserAction() {
      this.isInactive = true;
      if (this.$route.path !== '/login') {
        this.$router.push('/login');
      }
    },
  },
  beforeMount() {
    this.activateActivityTracker();
  },
  beforeUnmount() {
    this.deactivateActivityTracker();
    clearTimeout(this.userActivityTimeout);
    clearTimeout(this.userActivityThrottlerTimeout);
  },
};
</script>

<style scoped>
.logo-small {
  display: none;
}
.v-sheet.v-card {
  border-radius: 16px !important;
}
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: grey !important;
}
@media screen and (max-width: 400px) {
  .logo-large {
    display: none;
  }
  .logo-small {
    display: block;
  }
  .profile-style {
    padding: 0 !important;
    margin: 0 !important;
  }
  .icons-style {
    padding: 0 !important;
  }
  .v-btn:not(.v-btn--round).v-size--default {
    min-width: 40px !important;
    padding: 5px !important;
  }
  .tree-container {
    margin-left: 40px;
  }
  .fullname-profile {
    font-size: 32px;
  }
}
@media screen and (max-width: 770px) {
  .fullname-profile {
    font-size: 32px;
  }
  .profile-style {
    margin-left: 32px !important;
  }
}
</style>
