<template>
  <div>
    <app-header />
    <nav-bar />
    <v-container fluid class="container-styles">
      <div id="myModal" class="modal" v-if="deleteFileConfirmModal">
        <!-- Modal para confirmación de eliminación -->
        <ConfirmationModal
          :showModal="deleteFileConfirmModal"
          :onSuccess="deleteFile"
          @close="deleteFileConfirmModal = false" />
      </div>

      <!-- Contenido principal de la página -->
      <div class="content">
        <v-row >
          <v-col md="5" class="d-flex flex-column justify-content-center text-center tree-container">
            <v-card class="library-tree-card">
              <v-card-title class="ml-8 mt-8" style="font-family:'Poppins'"> <strong>Folder tree</strong></v-card-title>
              <v-treeview
                :items="items"
                item-value="name"
                class="ml-8"
                activatable
                open-on-click
              >
                <!-- Plantilla para el contenido adicional en cada nodo del árbol -->
                <template v-slot:prepend="{ item, isOpen }">
                  <v-dialog
                    v-if="!item.file"
                    v-model="showNewFolderModal"
                    persistent
                    hide-overlay
                    max-width="600px"
                  >
                    <template #activator="{ props }">
                      <v-btn
                        v-bind="props"
                        size="x-small"
                        density="default"
                        class="mr-2 round-button"
                        variant="elevated"
                        @click="setCurrentFolder(item.folder_id)"
                      >
                        <v-icon v-if="item.children">mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <v-card id="add-new-folder-dialog">
                      <v-card-title class="card-title-custom">
                        <span id="add-comment-span-dialog" class="text-h5 white--text">
                          Add new folder
                        </span>
                      </v-card-title>
                      <v-card-text>
                        <v-text-field v-model="folderName" required color="#6E57F8"/>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          class="btn-cancel"
                          text
                          @click="showNewFolderModal = false;folderName = null">
                          Close
                        </v-btn>
                        <v-btn
                          elevated
                          class="btn-accept"
                          :disabled="!folderName"
                          @click="addChildFolder(currentFolderId)"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-icon v-if="!item.file" @click="getFolderId(item)">
                    {{ isOpen ? 'mdi-folder-open' : 'mdi-folder' }}
                  </v-icon>
                  <v-icon v-else>
                    {{ files[item.file] }}
                  </v-icon>
                  <div style="display: inline">
                    <span :class="item.validation ? 'icon-valid' : 'icon-invalid'">
                      {{ getValidatedIcon(item.validation) }}
                    </span>
                    <v-icon v-if="item.file && deleteItemEnabled" medium color="red darken-2" @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                    <router-link
                      class="file-link"
                      v-if="item.file"
                      :to="`/library/document/${item.internal_id}/edit`"
                    />
                    {{ item.name }}
                  </div>
                </template>
              </v-treeview>
            </v-card>
          </v-col>

          <!-- Columna para mostrar los archivos en la carpeta seleccionada -->
          <v-col md="7" class="d-flex flex-column justify-content-center text-center">
            <v-card class="tableStyle">
              <v-card-title>
                <strong>Files in the selected folder</strong>
                <v-spacer />
                <v-text-field
                  color="rgb(101,123,219)"
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                />
              </v-card-title>
              <v-data-table :headers="headers" :items="documentos" :search="search">
                <template v-slot:[`item.title`]="{ item }">
                  <router-link :to="`/library/document/${item.internal_id}/edit`">
                    {{ item.title }}
                  </router-link>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import AppHeader from '../components/AppHeader.vue';
import NavBar from '../components/NavBar.vue';
import { VTreeview } from 'vuetify/labs/VTreeview';
import { fileServices } from '../services/fileServices';
import { userServices } from '../services/userService';
import { validationServices } from '../services/validationService';
import ConfirmationModal from '../components/ConfirmationModal.vue';


export default {
  name: 'LibraryView',
  components: {
    AppHeader,
    NavBar,
    VTreeview,
    ConfirmationModal
  },
  data() {
    return {
      email: '',
      password: '',
      registerName: '',
      registerLastName: '',
      role: '',
      showNewFolderModal: false,
      search: '',
      fileId: '',
      user: Cookies.get('user'),
      authorization: Cookies.get('Authorization'),
      study: Cookies.get('study'),
      files: {
        pdf: 'mdi-file',
        png: 'mdi-file-image',
        txt: 'mdi-file-document-outline',
      },
      tree: [],
      items: [],
      folder: '',
      documentos: [],
      headers: [
        { text: 'File', value: 'title' },
        { text: 'Creator', value: 'user' },
        { text: 'Created date (Time zone GMT)', value: 'historic_timestamp' },
        { text: 'Validation', value: 'validation' },
      ],
      folderName: null,
      deleteItemEnabled: false,
      alert: false,
      error: '',
      deleteFileConfirmModal: false,
      fileToDelete: '',
      currentFolderId: null,
    };
  },
  computed: {
    formValid() {
      return !(this.email && this.password);
    },
  },
  mounted() {
    const role = localStorage.getItem('role');
    if (role === undefined) {
      this.checkUserData();
    } else {
      this.role = role;
      if (this.canDeletePermissions(this.role)) {
        this.deleteItemEnabled = true;
      }
    }
    this.getFolders();
  },
  methods: {
    checkUserData() {
      userServices
        .checkUserData(this.authorization)
        .then((response) => {
          this.registerName = response.data.first_name;
          this.registerLastName = response.data.last_name;
          this.role = response.data.role;
          if (this.canDeletePermissions(this.role)) {
            this.deleteItemEnabled = true;
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    getFolders() {
      if (this.items.length > 0) {
        this.items = [];
      }
      fileServices
        .getItems(this.authorization, this.study)
        .then((response) => {
          const { data } = response;
          this.items.push(data);
          this.getFolderId(this.items[0]);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log('error folder:', error);
        });
    },
    getValidatedIcon(validation) {
      return validationServices.getValidatedIcon(validation);
    },
    getValidated(validation) {
      if (!validation) {
        return null;
      }
      return validation.validated;
    },
    deleteFile() {
      fileServices
        .deleteFile(this.authorization, this.fileToDelete.internal_id, this.fileToDelete.parent, this.study)
        .then((resp) => {
          if (resp.status === 204) {
            this.deleteFileConfirmModal = false;
            this.items = [];
            this.documentos = [];
            this.email = '';
            this.password = '';
            this.getFolders();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log('error deleteItem:', error);
        });
    },
    getFolderId(item) {
      this.documentos = [];
      fileServices
        .getInformation(this.authorization, item.folder_id)
        .then((response) => {
          response.data.forEach((document) => {
            const obj = {
              timestamp: document.timestamp,
              user: document.user.email,
              title: document.title,
              file_id: `${document.file_id}`,
              internal_id: document.internal_id,
              validation: validationServices.getValidatedIcon(document.validations[0] || {}),
              validated: this.getValidated(document.validations[0] || {}),
            };

            // Formatear el timestamp
            const [date, time] = obj.timestamp.split('T');
            const formattedTimestamp = `${date.replace(/-/g, '/')} ${time.slice(0, 5)}`;
            obj.historic_timestamp = formattedTimestamp;
            this.documentos.push(obj);
          });
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log('error folder:', error);
        });
    },
    setCurrentFolder(folderId) {
      this.showNewFolderModal = true;
      this.currentFolderId = folderId;
    },
    addChildFolder(currentFolderId) {
      fileServices
        .addFolder(this.authorization, currentFolderId, this.folderName)
        .then((response) => {
          if (response.status === 201) {
            this.showNewFolderModal = false;
            this.folderName = null;
            this.currentFolderId = null;
            this.getFolders();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log('error addChildFolder:', error);
        });
    },

    deleteItem(item) {
      this.deleteFileConfirmModal = true;
      this.fileToDelete = item;
    },
    canDeletePermissions(role) {
      const allowedRoles = ['Admin', 'Superadmin', 'Updater'];
      return allowedRoles.includes(role);
    },
  },
};
</script>

<style lang="scss" scoped>
  body {
    font-family: 'Poppins', sans-serif;
  }

  .btn-accept {
    background-color: #6E57F8 !important;
    color: white !important;
  }
  .btn-cancel {
    color: #6E57F8 !important;
  }
  .round-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #fdfcfc;
    border: 1px solid #ddd;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }

  .alertbox {
    margin-bottom: 16px;
  }

  .tableStyle {
    padding: 16px;
    font-family: 'Poppins', sans-serif;
    max-width: 100%; 
    width: 100%;
    overflow-x: auto;
    border-spacing: 0 15px;
  }

  .v-dialog {
    border-radius: 16px !important;
  }

  .v-treeview-node--active {
    background-color: #e0e0e0 !important;
  }

  .content {
    margin-top: 30px;
  }

  .library-tree-card {
    padding-bottom: 50px;
  }

  .v-application {
    .text-center {
      text-align: initial !important;
    }

    a {
      color: black !important;
      text-decoration: none;

      &:hover {
        color: #1976d2 !important;
      }
    }
  }

  .form-input {
    margin-bottom: 20px;
  }

  .file-link {
    color: black;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .icon-valid {
    color: green;
  }

  .icon-invalid {
    color: red;
  }
</style>

